import React from "react"

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 500,
  },
  textArea: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Contact = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>

      <form className={classes.container} noValidate autoComplete="off" name="contact" method="POST" action="/contact-success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <TextField
          id="name"
          name="Name"
          label="Name"
          helperText="Your name"
          required
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="email"
          label="Email"
          name="Email"
          helperText="Your email address"
          required
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="message"
          label="Message"
          name="Message"
          placeholder="Message"
          helperText="How can we help you"
          fullWidth
          multiline
          rowsMax="6"
          required
          className={classes.textArea}
          margin="normal"
        />
        <Button 
          variant="contained" 
          color="primary" 
          className={classes.button} 
          type="submit"
        >
          Send
        </Button>
      </form>

    </div>
  )
};

export default Contact
